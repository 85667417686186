<template>
  <notice-list class="content md"></notice-list>
</template>

<script>
    import NoticeList from "../../layouts/NoticeList";
    export default {
        name: "BuddhaNotice",
        components: {NoticeList}
    }
</script>

<style scoped>

</style>
